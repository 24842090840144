import * as React from "react";
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import FeatureGrid from '../components/FeatureGrid';
import FeatureGridWithOffsetIcons from '../components/FeatureGridWithOffsetIcons';
import CallToAction from '../components/CallToAction';
import { Pricing } from '../components/Pricing';
import { Faqs } from '../components/Faqs';
import { Footer } from "../components/Footer";

const IndexPage = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <FeatureGridWithOffsetIcons />
        <FeatureGrid />
        <CallToAction />
        <Pricing />
        <Faqs />
        <Footer />
      </main>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Café Zincan Home</title>;
