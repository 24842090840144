import * as React from 'react'
import {
    PuzzlePieceIcon,
    HeartIcon,
    TableCellsIcon,
    BoltIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Works with recipe plugins',
        description:
            'Café Zincan works with all of the recipe plugins you use to show accurate search results.',
        icon: PuzzlePieceIcon,
    },
    {
        name: 'Built for Food Bloggers',
        description:
            'Café Zincan Search is a botique product tailor made for you.  We will continue to add features and do things that cater directly to food bloggers.',
        icon: HeartIcon,
    },
    {
        name: 'View the searches',
        description:
            'Café Zincan Search has a dashboard in your WP Admin panel where you can research what users are searching for, what their journey looked like, and more.',
        icon: TableCellsIcon,
    },
    {
        name: 'Ligtning fast',
        description:
            'Café Zincan focuses on ligthning fast search hints and search results.  This is one of the biggest ways we encourage users to stay awhile and search for more recipes.',
        icon: BoltIcon,
    },
]

const FeatureGrid = () => {
    return (
        <div className="bg-white py-12">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <h2 className="text-lg font-semibold text-accent2">Search</h2>
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        Improve your site search
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        You did the work to get your reader to the site – we can help keep them on the site with an amazing search experience.
                    </p>
                </div>

                <div className="mt-10">
                    <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-primary text-white">
                                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default FeatureGrid;
