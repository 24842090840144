import * as React from 'react';
import Button from './Button'
import Container from './Container'

const CallToAction = () => {
    return (
        <section
            id="get-started-today"
            className="relative overflow-hidden bg-gradient-to-r from-secondary to-transparent py-32"
        >
            <Container className="relative">
                <div className="mx-auto max-w-lg text-center">
                    <h2 className="font-display text-3xl tracking-tight text-grey sm:text-4xl">
                        Improve your search today
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-grey">
                        If you're interested in learning more about the Café Zincan Search Plugin sign up to recieve email updates.
                    </p>
                    <Button href="/waitinglist" color="primary" className="mt-10">
                        Join the waiting list
                    </Button>
                </div>
            </Container>
        </section>
    )
}

export default CallToAction;
