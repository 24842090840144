import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from './Container';

const faqs = [
  [
    {
      question: 'Does Café Zincan have a content reel?',
      answer:
        'We do not have a content reel currently but are working on that as well – as well as other helpful widgets that help with user engagement.',
    },
    {
      question: 'How does the wait list work?',
      answer:
        'Add your name to the list and we will reach out to you when we are ready to show you a demo.',
    },
  ],[
    {
      question: 'Can I suggest new features to Café Zincan to build?',
      answer: 'Absolutely!  Café Zincan Search is built solely for Food Bloggers and so you get our undivided attention.',
    },
  ],[
    {
      question: 'Can I get some help installing the plugin?',
      answer:
        'Yes.  We provide step-by-step instructions for installing the plugin but can help install the plugin for you on your site.',
    },
  ],
  ]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-primary py-20 sm:py-32"
    >
    <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-white sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Here are some common questions we recieve.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
