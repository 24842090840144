import * as React from 'react'
import { Link } from 'gatsby'
import Container from '../components/Container'
import { NavLink } from '../components/NavLink'
import CafeZincanLogo from '../../assets/svgs/CafeZincanLogo.svg'

export function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <CafeZincanLogo className="mx-auto h-10 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
            </div>
          </nav>
        </div>
        <div className="text-center">
          <p className="mt-6 mb-10 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Zincan LLC. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
