import * as React from 'react'
import {
  ArrowPathIcon,
  DevicePhoneMobileIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  AdjustmentsHorizontalIcon,
  CogIcon,
  WindowIcon,
  HandThumbUpIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Mobile First Design',
    description: 'Café Zincan prioritizes mobile design because most web traffic arrives on mobile.  As you use the search you will see how we prioritze that.',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'History',
    description: 'Café Zincan uses local history caching to save what the user has searched for previously.  This makes pulling up that recipe they loved from your site so much easier!',
    icon: ClockIcon,
  },
  {
    name: 'Helpful Hints (not results)',
    description: 'On mobile, showing square images of all of your recipes is not helpful since the screen is not large enough to see them.  Instead, we show accurate and relevant search terms so the user can complete their search and get to a bigger screen (w/ no keyboard popped up)',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Curate your results',
    description: 'This is your content and you know it best.  If there’s something you want to promote in the results you can skew the results to favor certain recipes.',
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: 'No modals',
    description: 'Current search result plugins jump right to a modal popup window that spans your site.  Café Zincan becomes one with your site and displays results in your blog ... not on your blog.',
    icon: WindowIcon,
  },
  {
    name: 'Your logo, not ours',
    description: 'Café Zincan does all it can to keep your look and feel of your site throughout the search process.  This means your logo is up top.',
    icon: ServerIcon,
  },
]

const FeatureGridWithOffsetIcons = () => {
  return (
    <div id="features" className="relative bg-white py-16 sm:py-24 lg:py-32 bg-secondary">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-grey-600">Better user engagement</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Quick, simple, and helpful search
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
            You make beautiful content.  It's important that your readers can easily navigate your site.  Having a simple and easy-to-use search widget removes any friction from allowing the reader to navigate and discover more great content.  One accurate search result can make for a life long loyal reader.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 bg-white-500">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-accent2 p-3 shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureGridWithOffsetIcons;
